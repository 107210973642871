import { FlowStepType } from '../constants'
import { useRecoilValue } from 'recoil'
import { flowConfigState } from '../Recoil'
import { useLocation } from 'react-router-dom'

export const useStepDescription = () => {
  const flowSteps = useRecoilValue(flowConfigState)
  const { pathname } = useLocation()
  const stepDescription = flowSteps.find(
    (step: FlowStepType) =>
      step.path === pathname.substring(1).toLocaleLowerCase()
  )?.description ?? ''

  return { stepDescription }
}
