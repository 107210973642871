import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { LocalStorageKeys, ROUTES } from '../../constants'
import { flowConfigState, hasInspectionSignedState, hasShortInspectionCompletedState, isClientSigningFlowState,
} from '../../Recoil'
import { useRecoilValue } from 'recoil'

const ProtectedContent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const reason = localStorage.getItem(LocalStorageKeys.REASON)
  const uuid = localStorage.getItem(LocalStorageKeys.UUID)
  const hasInspectionSigned = useRecoilValue(hasInspectionSignedState)
  const hasInspectionCompleted = useRecoilValue(hasShortInspectionCompletedState)
  const isClientSigningFlow = useRecoilValue(isClientSigningFlowState)
  const flowSteps = useRecoilValue(flowConfigState)

  useEffect(() => {
    if (hasInspectionCompleted === true) {
      navigate(`/${ROUTES.PUBLIC_PATH.THANK_YOU}`, {
        replace: true,
      })
    } else if (hasInspectionSigned
        && location.pathname !== `/${ROUTES.PRIVATE_PATH.SUMMARY}`
        && isClientSigningFlow !== 'true'
        && flowSteps?.find(step => step.path === ROUTES.PRIVATE_PATH.SCAN_QR)) {
      navigate(`/${ROUTES.PRIVATE_PATH.SCAN_QR}`, {
        replace: true,
      })
    } else if (location.pathname !== `/${ROUTES.PUBLIC_PATH.THANK_YOU}` && !uuid) {
      navigate(`/${ROUTES.PUBLIC_PATH.THANK_YOU}`, {
        replace: true,
      })
    }
  }, [hasInspectionCompleted, hasInspectionSigned, location.pathname, reason])

  return <Outlet />
}

export default ProtectedContent