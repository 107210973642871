
export const tiresTypeConstant = [
  { label: 'summer', code: 'TIR-SUM' },
  { label: 'all-season', code: 'TIR-ALL' },
  { label: 'winter', code: 'TIR-WINT' },
]

export const tiresThreadConstant = [
  { label: '< 1.6 mm', code: 'THREAD-POOR' },
  { label: '1,6 – 3,6 mm', code: 'THREAD-ADEQ' },
  { label: '> 3.6 mm', code: 'THREAD-GOOD' },
]

export const tiresRimConstant = [
  { label: 'alloy', code: 'RIM-ALLOY' },
  { label: 'steel', code: 'RIM-CAPS' },
]

export const TreadDepthConstant = {
  min: 0,
  max: 9.9,
  step: 0.1,
  placeholder: '0',
  decimalSeparator: ',',
  decimalAmount: 1,
}

export const tiresNames = ['leftFront', 'leftRear', 'rightFront', 'rightRear']
