import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import './styles/_global.scss'
import AllRoutes from './Routes'
import { RecoilRoot } from 'recoil'
import * as Sentry from '@sentry/react'
import './utils/i18n'
import Loading from './components/Loading'
import ScrollToTop from './components/ScrollToTop'
import ErrorFallback from './components/ErrorFallback'
import { ConfigCatProvider, PollingMode } from 'configcat-react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './Layout/Header/header.scss'

Sentry.init({
  dsn: 'https://80914ddd31014a92a530b112f0ff151e@o221264.ingest.sentry.io/6376674',
  ...(process.env.REACT_APP_ENV && { environment: process.env.REACT_APP_ENV }),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/api\/.*/],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENV === 'production',
})

const container = document.getElementById('root') as Element
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Sentry.ErrorBoundary fallback={ <ErrorFallback /> }>
          <Suspense fallback={<Loading />}>
            <ConfigCatProvider
              sdkKey={process.env.REACT_APP_CONFIG_CAT_KEY}
              pollingMode={PollingMode.ManualPoll}
            >
              <ScrollToTop />
              <AllRoutes />
            </ConfigCatProvider>
          </Suspense>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
)

serviceWorkerRegistration.register()