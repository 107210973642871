import { isAuthenticatedState } from './../Atoms/isAuthenticatedState'
import { selector } from 'recoil'
import { getInspectionIdByUuid } from '../../api/apis'
import { uuidState } from '../Atoms/uuidState'
import { tokenState } from '../Atoms/tokenState'

export const inspectionIdSelector = selector<string>({
  key: 'inspectionIdSelector',
  get: async({ get }) => {
    const isAuthenticated = get(isAuthenticatedState)

    if(isAuthenticated) {
      const uuid = get(uuidState)
      const token = get(tokenState)

      if (uuid && token) {
        const inspectionId = await getInspectionIdByUuid(uuid)
        return inspectionId
      }
    }
    return ''
  },
})