import { atom, selector } from 'recoil'
import { getTires } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { tireBrandListSelector } from '../'
import { retrieveTiresData } from '../../helpers/retrieveTiresData'

export interface TireType {
  name: string,
  type: string,
  remainingTreadDepth?: string,
  remainingTreadDepthExact?: number,
  brand: { id: string, code: string, displayText: string },
}

export type TiresType = {
  extraTireSet: boolean,
  mediaBase64: {
    filename: string,
    data: string
  },
  rimType: string,
  tires: TireType[],
}

export const tiresState = atom<TiresType>({
  key:'tiresState',
  default: selector({
    key: 'tiresState/default',
    get: ({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      const tiresBrandList = get(tireBrandListSelector)
      return getTires(+inspectionId).then(res => retrieveTiresData(tiresBrandList, res),
      )
    },
  }),
})
